export const AUTH0_CLIENT_ID = 'oZxgah56qeCV7NVRYo8UO1mzA4u5nOIw'

export const AUTH0_DOMAIN = 'infratab-staging.auth0.com'

export const AREA51_DOMAIN = 'https://area51.freshtime.xyz'

export const ACCOUNTS_DOMAIN = 'https://portal.freshtime.xyz'

export const API_URL = 'https://accounts.infratab.xyz/api/v2'

export const RAVEN_CONFIG = {
  development: {
    DNS: undefined,
    environment: 'development'
  },
  staging: {
    DNS: 'https://30af5703c1924895a806c8120ca30157@sentry.io/109535',
    environment: 'staging'
  },
  production: {
    DNS: 'https://30af5703c1924895a806c8120ca30157@sentry.io/109535',
    environment: 'production'
  }
}

export const INFRATAB_ORG_ID = '7c86916e-b144-436c-a662-f7d7d2fa99eb'